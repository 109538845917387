import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Alert } from "@patternfly/react-core"
class AlertTypes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Alert title="Default alert title" />
        <Alert variant="info" title="Info alert title" />
        <Alert variant="success" title="Success alert title" />
        <Alert variant="warning" title="Warning alert title" />
        <Alert variant="danger" title="Danger alert title" />
      </React.Fragment>
    )
  }
}

const Header = ({ siteTitle }) => (
  <header>
    <AlertTypes />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
